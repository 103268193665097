import React from "react";
import { CListGroup, CListGroupItem } from "@coreui/react";

import "./CLI.css";

const CLI = ({ apiKey, account }) => {
  return (
    <CListGroup className="kntsCLICommands">
      <CListGroupItem>
        <div className="d-flex w-100 justify-content-between">
          <h6 className="mb-1"># Install klusternetes cli</h6>
          <small>Use latest release tag</small>
        </div>
        {/* <p className="mb-1 copyButton" id="url">
          <i className="fa fa-chevron-right" />
          {`curl -sL https://get-klusternetes-dev.web.app | TAG=v0.0.25 bash`}
        </p>
        <button
          className="right"
          onClick={() =>
            navigator.clipboard.writeText(
              document.getElementById("url").textContent
            )
          }
        >
          Copy
        </button> */}
      </CListGroupItem>

      <CListGroupItem>
        <div className="d-flex w-100 justify-content-between">
          <h6 className="mb-1"># Authenticate with provided token</h6>
          <small className="text-medium-emphasis">
            Verify the current api key
          </small>
        </div>
        <p className="mb-1 copyButton" id="token">
          <i className="fa fa-chevron-right" />
          {`knts cluster auth --token="${apiKey}"`}
        </p>
        <button
          className="right"
          onClick={() =>
            navigator.clipboard.writeText(
              document.getElementById("token").textContent
            )
          }
        >
          Copy
        </button>
      </CListGroupItem>

      <CListGroupItem>
        <div className="d-flex w-100 justify-content-between">
          <h6 className="mb-1"># Create cluster</h6>
        </div>
        <p className="mb-1 copyButton" id="createCluster">
          <i className="fa fa-chevron-right" />
          {"knts cluster create mycluster"}
        </p>
        <button
          className="right"
          onClick={() =>
            navigator.clipboard.writeText(
              document.getElementById("createCluster").textContent
            )
          }
        >
          Copy
        </button>
      </CListGroupItem>

      <CListGroupItem>
        <div className="d-flex w-100 justify-content-between">
          <h6 className="mb-1"># Get cluster info</h6>
        </div>
        <p className="mb-1 copyButton" id="getClusters">
          <i className="fa fa-chevron-right" />
          {"knts cluster list"}
        </p>
        <button
          className="right"
          onClick={() =>
            navigator.clipboard.writeText(
              document.getElementById("getClusters").textContent
            )
          }
        >
          Copy
        </button>
        <br />
        {/* <p className="mb-1 copyButton" id="myClusters">
          <i className="fa fa-chevron-right" />
          {"delete"}
        </p>
        <button
          className="right"
          onClick={() =>
            navigator.clipboard.writeText(
              document.getElementById("myClusters").textContent
            )
          }
        >
          Copy
        </button> */}
      </CListGroupItem>

      <CListGroupItem>
        <div className="d-flex w-100 justify-content-between">
          <h6 className="mb-1"># Get kubeconfig for your cluster</h6>
        </div>
        <p className="mb-1 copyButton" id="kubeconfig">
          <i className="fa fa-chevron-right" />
          {"knts cluster kc mycluster"}
        </p>
        <button
          className="right"
          onClick={() =>
            navigator.clipboard.writeText(
              document.getElementById("kubeconfig").textContent
            )
          }
        >
          Copy
        </button>
      </CListGroupItem>

      <CListGroupItem>
        <div className="d-flex w-100 justify-content-between">
          <h6 className="mb-1"># Check your cluster access</h6>
        </div>
        <p className="mb-1 copyButton" id="clusterAccess">
          <i className="fa fa-chevron-right" />
          {`kubectl get nodes`}
        </p>
        <button
          className="right"
          onClick={() =>
            navigator.clipboard.writeText(
              document.getElementById("clusterAccess").textContent
            )
          }
        >
          Copy
        </button>
      </CListGroupItem>

      <CListGroupItem>
        <div className="d-flex w-100 justify-content-between">
          <h6 className="mb-1"># Exporting KUBECONFIG</h6>
          <small className="text-medium-emphasis">
            If you have any existing KUBECONFIG
          </small>
        </div>
        <p className="mb-1 copyButton" id="exporting">
          <i className="fa fa-chevron-right" />
          {`export KUBECONFIG=~/.knts/config`}
        </p>
        <button
          className="right"
          onClick={() =>
            navigator.clipboard.writeText(
              document.getElementById("exporting").textContent
            )
          }
        >
          Copy
        </button>
      </CListGroupItem>
    </CListGroup>
  );
};

export default CLI;
